body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 400;
  src: local('Cinzel Decorative Regular'), local('CinzelDecorative-Regular'), url(https://fonts.gstatic.com/s/cinzeldecorative/v7/daaCSScvJGqLYhG8nNt8KPPswUAPni7TTMxpazyD.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWC9bVRFeCDpK1A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWCRbVRFeCDpK1A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWCpbVRFeCDo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lancelot';
  font-style: normal;
  font-weight: 400;
  src: local('Lancelot'), url(https://fonts.gstatic.com/s/lancelot/v8/J7acnppxBGtQEulG4KYxzp5gGDAbnCA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lancelot';
  font-style: normal;
  font-weight: 400;
  src: local('Lancelot'), url(https://fonts.gstatic.com/s/lancelot/v8/J7acnppxBGtQEulG4KY_zp5gGDAb.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  src: local('Life Savers'), local('LifeSavers-Regular'), url(https://fonts.gstatic.com/s/lifesavers/v8/ZXuie1UftKKabUQMgxAal8liHAt5u8vAne0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  src: local('Life Savers'), local('LifeSavers-Regular'), url(https://fonts.gstatic.com/s/lifesavers/v8/ZXuie1UftKKabUQMgxAal8lsHAt5u8vA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  src: local('Playball'), local('Playball-Regular'), url(https://fonts.gstatic.com/s/playball/v8/TK3gWksYAxQ7jbsKcg8KneptKZ2s7zg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  src: local('Playball'), local('Playball-Regular'), url(https://fonts.gstatic.com/s/playball/v8/TK3gWksYAxQ7jbsKcg8EneptKZ2s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'HarabaraHand';
  src: url(/static/media/HarabaraHand.503b4d8d.ttf);
}

@font-face {
  font-family: 'Frente';
  src: url(/static/media/FrenteH1-Regular.729c2fe8.otf);
}

/* latin-ext */
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  src: local('Great Vibes'), local('GreatVibes-Regular'), url(https://fonts.gstatic.com/s/greatvibes/v6/RWmMoKWR9v4ksMfaWd_JN9XLiaQoDmlrMlY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Great Vibes';
  font-style: normal;
  font-weight: 400;
  src: local('Great Vibes'), local('GreatVibes-Regular'), url(https://fonts.gstatic.com/s/greatvibes/v6/RWmMoKWR9v4ksMfaWd_JN9XFiaQoDmlr.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: local('Caveat Regular'), local('Caveat-Regular'), url(https://fonts.gstatic.com/s/caveat/v5/Wnz6HAc5bAfYB2Q7YjYYiAzcPDKo.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Caveat';
  font-style: normal;
  font-weight: 400;
  src: local('Caveat Regular'), local('Caveat-Regular'), url(https://fonts.gstatic.com/s/caveat/v5/Wnz6HAc5bAfYB2Q7aDYYiAzcPDKo.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


.App {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-family: 'Frente', 'Georgia', serif;
  /*font-family: 'Caveat', cursive;*/
  font-family: monospace;
  background-color: rgb(47, 47, 47);
   /*font-family: 'Life Savers';
  font-family: 'Lancelot';
  font-family: 'Cinzel Decorative';
  font-family: 'Katibeh';
  font-family: 'Lancelot';
  font-family: 'Playball';
  font-family: 'Great Vibes', cursive;
  text-shadow: 0 0 1px #313f59;*/
}

.handwritten {
  text-align: center;
  /* font-family: 'HarabaraHand', 'Georgia', serif; */
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  min-height: 20vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #ced0d4;
}

.App-body {
  min-height: 80vh;
  display: block;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(20px + 2vmin);
  color: #a9a9a9;
}

.App-link {
  color: #959595;
  /*font-family: 'Frente', 'Georgia', serif;*/
  /*font-size: xx-large;*/
}

.home-link {
  /*color: #61dafb;*/
  color: #959595;
  /* font-family: 'Frente', 'Georgia', serif; */
  text-decoration: none;
  font-family: monospace;
  /*font-size: xx-large;*/
  /*border: 1px solid #516790;*/
  display: inline-block;
  background: #4a4a4a;
  min-width: 50%;
  padding: 8px;
  margin: 10px;
  /*text-shadow: 0 0 1px #ececec;*/
  font-weight: bold;
  box-shadow: 1px 1px 4px #040404;
}

.arrow-link {
  width: 50px;
  height: 50px;
  display: inline-block;
  background: #91a8d0;
  text-decoration: none;

}

.App-link-no-decoration {
  color: #516790;
  color: #313f59;
  text-decoration: none;
}

.titleLink {
  /*font-family: 'Frente', 'Georgia', serif;*/
  text-decoration: none;
}

.titleLink:visited {
  color: inherit;
}

.titleLink:link {
  color: inherit;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


.blue{
  color: #313f59;
}

.header{
  /* color: #516790;*/
  color: #9abcfc;
  text-align: center;
  /*font-family: 'HarabaraHand', 'Georgia', serif;*/
  /* font-family: 'Great Vibes', cursive; */
  font-size: calc(40px + 2vmin);
  text-shadow: 0 0 3px #313f59;
  margin-top: 20px;
  margin-bottom: 20px;
}

h2, h3 {
    text-shadow: 0 0 2px #313f59;
}

.contactIcon {
    height: 30px;
    width: 30px;
    margin-right: 20px;
}

.photo{
  width: 90%;
  max-width: 700px;
  border: 2px solid #516790;
  margin: 24px;
}

.mapcontainer {
  /* border: 1px solid red;*/
}

.mapframe {
    width: 90%;
    background: #516790;
}

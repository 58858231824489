/* latin */
@font-face {
  font-family: 'Cinzel Decorative';
  font-style: normal;
  font-weight: 400;
  src: local('Cinzel Decorative Regular'), local('CinzelDecorative-Regular'), url(https://fonts.gstatic.com/s/cinzeldecorative/v7/daaCSScvJGqLYhG8nNt8KPPswUAPni7TTMxpazyD.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWC9bVRFeCDpK1A.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE80-FEFC;
}
/* latin-ext */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWCRbVRFeCDpK1A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Katibeh';
  font-style: normal;
  font-weight: 400;
  src: local('Katibeh-Regular'), url(https://fonts.gstatic.com/s/katibeh/v6/ZGjXol5MQJog4bxDWCpbVRFeCDo.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lancelot';
  font-style: normal;
  font-weight: 400;
  src: local('Lancelot'), url(https://fonts.gstatic.com/s/lancelot/v8/J7acnppxBGtQEulG4KYxzp5gGDAbnCA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lancelot';
  font-style: normal;
  font-weight: 400;
  src: local('Lancelot'), url(https://fonts.gstatic.com/s/lancelot/v8/J7acnppxBGtQEulG4KY_zp5gGDAb.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  src: local('Life Savers'), local('LifeSavers-Regular'), url(https://fonts.gstatic.com/s/lifesavers/v8/ZXuie1UftKKabUQMgxAal8liHAt5u8vAne0.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Life Savers';
  font-style: normal;
  font-weight: 400;
  src: local('Life Savers'), local('LifeSavers-Regular'), url(https://fonts.gstatic.com/s/lifesavers/v8/ZXuie1UftKKabUQMgxAal8lsHAt5u8vA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  src: local('Playball'), local('Playball-Regular'), url(https://fonts.gstatic.com/s/playball/v8/TK3gWksYAxQ7jbsKcg8KneptKZ2s7zg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Playball';
  font-style: normal;
  font-weight: 400;
  src: local('Playball'), local('Playball-Regular'), url(https://fonts.gstatic.com/s/playball/v8/TK3gWksYAxQ7jbsKcg8EneptKZ2s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}